import React from "react"
import { graphql } from "gatsby"
import { SEO, Layout, Banner, PortableText, Posts } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    sanityPages(slug: { current: { eq: $slug } }) {
      templateKey
      title
      description
      banner {
        heading
        description
        buttons {
          text
          linkUrl
          newTab
          colour
          icon {
            asset {
              gatsbyImageData(width: 36, placeholder: NONE)
            }
          }
        }
      }
      articles {
        title
        link
      }
      _rawSections
      slug {
        current
      }
    }
  }
`

const HomePageTemplate = ({ data }) => {
  let page = data.sanityPages

  return (
    <Layout>
      <Banner banner={page.banner} />
      <Posts articles={page.articles} />
      <PortableText blocks={page._rawSections} />
    </Layout>
  )
}

export default HomePageTemplate

export function Head({ data }) {
  let { title, description } = data.sanityPages

  return <SEO title={title} description={description} />
}
